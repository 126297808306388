import * as React from "react";
import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import {  useNavigate } from "react-router-dom";

const drawerWidth = 240;

function ResponsiveDrawer(props) {
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [isClosing, setIsClosing] = React.useState(false);
const userRole = localStorage.getItem("profile");
  const handleDrawerClose = () => {
    setIsClosing(true);
    setMobileOpen(false);
  };

const navigate = useNavigate(); 
  const handleDrawerTransitionEnd = () => {
    setIsClosing(false);
  };


  const handleDrawerToggle = () => {
    if (!isClosing) {
      setMobileOpen(!mobileOpen);
    }
  };

  const sidebarData = [
    {
      id:1,
      title:"Users",
      path:"/users"
    },
    {
      id:2,
      title:"Warn",
      path:"/warn"
    },
    {
      id:3,
      title:"Reports",
      path:"/reports"
    },
    {
      id:4,
      title:"Premium Users",
      path:"/paiduser"
    },
  
  ];

  if (JSON.parse(userRole).role === "admin") {
    sidebarData.push(  {
      id:5,
      title:"Moderator Creation",
      path:"/modcreation"
    },
    {
      id:6,
      title:"Logs",
      path:"/ownerLogs"
    }
  );

  }

  const drawer = (
    <div>
      <Toolbar className="text-black py-5 flex justify-center items-center gap-4">
   <img src="/logo.jpg" className="rounded-full w-10 " /> <p className="uppercase">ContentMatch</p>
</Toolbar>
       <Divider />
      <List>
        {sidebarData.map(
          (text) => (
            <ListItem key={text.id} onClick={console.log("Button CLick")} className="px-4" disablePadding>
              <ListItemButton   onClick={() => navigate(text.path)} key={text.id} > 
                {/* <ListItemIcon>
                {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
              </ListItemIcon> */}
              
<ListItemText  primary={text.title} />
              </ListItemButton>
            </ListItem>
          )
        )}
      </List>
      <Divider />
    </div>
  );

 // Empty dependency array means this runs once on mount

  // Remove this const when copying and pasting into your project.
  const container =
    window !== undefined ? () => window().document.body : undefined;
const handleLogout = () => {
    localStorage.removeItem("token");
navigate("/");
}
  return (
    <Box sx={{ display: "flex", }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },
          backgroundColor:"#fff",
          boxShadow: "none",
          borderBottom: "1px solid #e0e0e0",
        }}
className="py-2 "
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            // color="black"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display:  { sm: "none" }, }}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6"  noWrap component="div" sx={{ marginLeft: "auto" }}>
      <div onClick={handleLogout} className="flex text-white bg-black rounded-lg justify-center items-center gap-4 border border-white px-6 py-2 cursor-pointer">
    Logout <img width="30" height="30" src="https://img.icons8.com/fluency-systems-regular/30/ffffff/exit--v1.png" alt="exit--v1"/>
      </div>
          </Typography>
        </Toolbar>
      </AppBar>
      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onTransitionEnd={handleDrawerTransitionEnd}
          onClose={handleDrawerClose}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: "none", sm: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          width: { sm: `calc(100% - ${drawerWidth}px)` },
        }}
      >
        <Toolbar />
        {props.children}
      </Box>
    </Box>
  );
}

ResponsiveDrawer.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * Remove this when copying and pasting into your project.
   */
  window: PropTypes.func,
};

export default ResponsiveDrawer;

import React, { useState, useEffect } from "react";
import ResponsiveDrawer from "../../Layout/Layout";
import { Pagination } from "@mui/material";
import { ClipLoader } from "react-spinners";


function AllReports() {
  const [reports, setReports] = useState([]);
  const [loading, setLoading] = useState(true);

  const [numPagination, setNumPagination] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    const fetchReports = async () => {
      const authToken = localStorage.getItem("token");
      try {
        const response = await fetch(
          `https://automating.site:8080/admin/owner/getreports?page=${currentPage}`,
          {
            method: "GET",
            headers: {
              Authorization: `${authToken}`
            }
          }
        );
        if(response.ok) {
          const data = await response.json();
          console.log(data.reports.map(report => report.user && report.user.user.firstName));

          setReports(data.reports);
          setLoading(false);
        }
        if (!response.ok) {
          throw new Error("Failed to fetch reports");
        }
        const data = await response.json();
        console.log(data);
        setReports(data.reports);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching reports:", error);
        setLoading(false);
      }
    };

    fetchReports();
  }, []);

  const handleDelete = async (id) => {
setLoading(true);
    try {
      const authToken = localStorage.getItem("token");
      const response = await fetch(
        "https://automating.site:8080/admin/deletereport",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: authToken
          },
          body: JSON.stringify({ reportId:id })
        }
      );
      if (response.ok) {
        // Report deleted successfully
        // Add your logic here
        const newReports = reports.filter((report) => report._id !== id);
        setReports(newReports);
        setLoading(false);
      } else {
        // Report deletion failed
        // Add your logic here
        setLoading(false);
      }
    } catch (error) {
      console.error("Error deleting report:", error);
      setLoading(false);
      // Add your logic here
    }
  }

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  if (loading) {
    return <div className="flex flex-col justify-center items-center py-8 "> 
<ClipLoader color="#000"  size={100} />
 <p>
  Loading...
 </p>
    </div>;
  }

  return (
    <ResponsiveDrawer>
      <div>
        <p className="flex justify-start items-start w-full text-black py-5 text-4xl">All Reports</p>
        <hr className="border border-neutral-500 w-full mb-5"/>

        <table className="w-full">
          <thead className="text-left p-3 bg-neutral-200">
            <tr>
              <th className="p-3">User Name</th>
              <th className="p-3">Report Message</th>
              <th className="p-3">Created At</th>
              <th className="p-3">Actions</th> {/* Add Actions column */}
            </tr>
          </thead>
          <tbody>
            {reports.map((report) => (
              <tr key={report._id} className=" even:bg-neutral-200">
                <td className="p-3">{report.user && report.user.user.firstName ? report.user.user.firstName : "N/A"}</td>
                <td className="p-3">{report.reportMessage}</td>
                <td className="p-3">{new Date(report.createdAt).toLocaleString()}</td>
                <td className="p-3">
                  <button onClick={() => handleDelete(report._id)} className="bg-red-500 rounded-md p-2">
                  <img width="20" height="20" src="https://img.icons8.com/ios-glyphs/30/ffffff/filled-trash.png" alt="filled-trash"/>
                  </button> {/* Add delete button */}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="flex justify-center items-center py-4">
        <Pagination
          count={numPagination}
          page={currentPage}
          onChange={handlePageChange}
          sx={{ marginTop: "10px" }}
        />
      </div>
    </ResponsiveDrawer>
  );
}

export default AllReports;

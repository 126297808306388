import React, { useEffect, useState } from 'react';
import ResponsiveDrawer from '../../Layout/Layout';
import { ClipLoader } from 'react-spinners';

const Ownerogs = () => {
    const [logs, setLogs] = useState([]);
    const [filter, setFilter] = useState('all'); // Step 1: Add new state for filter
    const [searchQuery, setSearchQuery] = useState(''); // Step 1: Add new state for search query
    const [showOptions, setShowOptions] = useState(false); // Step 1: State for options list visibility
    const [isLoading, setIsLoading] = useState(true); // Step 1: Add new state for loading status

    const options = [
        { value: 'all', label: 'All' },
        { value: 'moderator', label: 'Moderator' },
        { value: 'admin', label: 'Admin' }
    ];

    const handleOptionClick = (value) => {
        setFilter(value); // Update the filter state
        setShowOptions(false); // Hide the options list
    };
    useEffect(() => {
      const fetchLogs = async () => {
        setIsLoading(true); // Ste
        const token = localStorage.getItem('token');
        const config = {
          method: 'GET',
          headers: {
            'Authorization': token
          }
        };
  
        try {
          const response = await fetch('https://automating.site:8080/admin/getlogs', config);
          const data = await response.json();
          setLogs(data);
          setIsLoading(false); // Step 2: Set loading status to false on success
        } catch (error) {
            setIsLoading(false); // Step 2: Set loading status to false on error
          console.error('Error fetching logs:', error);
        }
      };
  
      fetchLogs();
    }, []);

    const handleFilterChange = (event) => {
        setFilter(event.target.value); // Update the filter state based on selection
    };

 
    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value.toLowerCase()); // Update the search query state
    };

    // Filter logs based on the selected filter option and search query
    const filteredLogs = logs.logsPaginated?.filter(log => {
        if (filter !== 'all' && log.adminId.role.toLowerCase() !== filter) return false;
        if (searchQuery && !Object.values(log.adminId).join(' ').toLowerCase().includes(searchQuery)) return false;
        return true;
    });

    return (
        <div>
            <ResponsiveDrawer>
            <p className='text-3xl w-full text-center'>Logs </p>

               <div className='w-full flex gap-x-5 py-5'>

<div className='relative w-4/5'>
<svg xmlns="http://www.w3.org/2000/svg" className='absolute w-5 top-3 left-3' viewBox="0 0 50 50" ><path d="M 21 3 C 11.621094 3 4 10.621094 4 20 C 4 29.378906 11.621094 37 21 37 C 24.710938 37 28.140625 35.804688 30.9375 33.78125 L 44.09375 46.90625 L 46.90625 44.09375 L 33.90625 31.0625 C 36.460938 28.085938 38 24.222656 38 20 C 38 10.621094 30.378906 3 21 3 Z M 21 5 C 29.296875 5 36 11.703125 36 20 C 36 28.296875 29.296875 35 21 35 C 12.703125 35 6 28.296875 6 20 C 6 11.703125 12.703125 5 21 5 Z"/></svg>
            <input 
                    type="text" 
                    placeholder="Search..." 
                    value={searchQuery} 
                    onChange={handleSearchChange} 
                    className="mb-4 w-full ps-10 border border-gray-500 rounded-md p-2 "
                />
</div>
    <button onClick={() => setShowOptions(!showOptions)} className="flex relative h-10 text-lg px-3 w-[20%] justify-between items-center  rounded-md border border-gray-300 shadow-sm  bg-white  font-medium text-gray-700 hover:bg-gray-50 ">
                {options.find(option => option.value === filter)?.label || 'Filter'} {/* Display the selected option */}
                <img  className={`${showOptions ?"rotate-180  transition-all duration-200 ease-in-out" :"rotate-0 transition-all duration-200 ease-in-out"}  absolute right-2 w-3 top-3`} src="https://img.icons8.com/external-tanah-basah-basic-outline-tanah-basah/24/external-chevron-arrows-tanah-basah-basic-outline-tanah-basah-4.png" alt="external-chevron-arrows-tanah-basah-basic-outline-tanah-basah-4"/>
            </button>

{showOptions && (
                <div className="origin-top-right absolute right-5 mt-8 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                    <div className="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
                        {options.map(option => (
                            <button key={option.value} onClick={() => handleOptionClick(option.value)} className="text-gray-700 block w-full text-left px-4 py-2 text-sm hover:bg-gray-100 hover:text-gray-900" role="menuitem">
                                {option.label}
                            </button>
                        ))}
                    </div>
                </div>
            )}
               </div>
               {isLoading ? <div className='flex justify-center gap-x-5 items-center'><ClipLoader className='w-10' /> Loading...</div> :
               
                <table className='w-full overflow-x-scroll border-collapse'>
                    <thead className='w-full'>
                        <tr className='text-left bg-[#eee] w-full'>
                            <th className='p-4 font-sans'>Email</th>
                            <th className='p-4 font-sans'>Name</th>
                            <th className='p-4 font-sans'>Phone Number</th>
                            <th className='p-4 font-sans'>Description</th>
                            <th className='p-4 font-sans'>Date of Birth</th>
                            <th className='p-4 font-sans'>Profile Pic</th>
                            <th className='p-4 font-sans'>Total Post</th>
                            <th className='p-4 font-sans'>Role</th>
                        </tr>
                    </thead>
                    <tbody>
                        {filteredLogs?.map((log, index) => (
                            <tr key={log._id} className='text-left'>
                                <td className='border px-4 py-2'>{log.adminId.email}</td>
                                <td className='border px-4 py-2'>{log.adminId.firstName} {log.adminId.lastName}</td>
                                <td className='border px-4 py-2'>{log.adminId.phoneNumber}</td>
                                <td className='border px-4 py-2 lowercase text-sm'>{log.description}</td>
                                <td className='border px-4 py-2'>{new Date(log.adminId.Dob).toLocaleDateString('en-GB')}</td>
                                <td className='border px-4 py-2 '><img src={log.profileId?.profilepic} className='w-10 h-10' /></td>
                                <td className='border px-4 py-2 capitalize'>{log.profileId?.totalPosts}</td>
                                <td className='border px-4 py-2 capitalize'>{log.adminId.role}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            }
            </ResponsiveDrawer>
        </div>
    );
}

export default Ownerogs;
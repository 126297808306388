import React, { useRef, useState } from "react";
import TextField from "@mui/material/TextField";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import { ClipLoader } from "react-spinners";
import FailModal from "../components/Modal/failModal";
import TwoFactorAuthModal from "../components/Modal/TwoFactorAuthModal";

function Login() {
  const navigate = useNavigate();
  const usernameRef = useRef(null);
  const passwordRef = useRef(null);
  const [showError, setShowError] = useState(false);
  const [loader, setLoader] = useState(false);
  const [loaderAuth, setLoaderAuth] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [twoFactorAuthCode, setTwoFactorAuthCode] = useState(""); // Add this line to create a state for the input value
  const [email,setEmail] = useState("");
  const [showOTPError, setShowOTPError] = useState(false);


  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoader(true);
    const username = usernameRef.current.value;
    setEmail(username);
    const password = passwordRef.current.value;

    const raw = JSON.stringify({
      "username": username,
      "password": password,
    });
    try {
      // Make API call to AWS backend
      const response = await fetch(
        "https://automating.site:8080/admin/loginOwnMod",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          redirect: "follow",
          body: raw,
        }
      );

      const data = await response.json();
      if (response.status === 200) {
localStorage.setItem("Email", data.email);
        setLoader(false);
        setShowError(false);
        setShowModal(true); // Show the modal on successful login
      }
      if (!response.ok) {
        setShowError(true);

        setLoader(false);
        throw new Error(data.message);
      }

      // If API call is successful, handle the response data
      
      setLoader(false);
    } catch (error) {
      setLoader(false);
      console.error("Error logging in:", error);
      // Handle error
    }
  };

  setTimeout(() => {
    setShowError(false);
  }, 5000);

const twoFactorAuthSubmit = async (e) => {
  e.preventDefault();
setLoaderAuth(true)
  try {
    // Make API call for two-factor authentication
    const response = await fetch(
      "https://automating.site:8080/admin/verify2fa",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email: localStorage.getItem("Email"), // Assuming email is stored in localStorage after initial login
          otp: twoFactorAuthCode,
        }),
      }
    );
    const data = await response.json();
    if (response.status === 200) {  
      setLoaderAuth(false);
      setTimeout(() => {
        setShowModal(false); // Close the modal on successful two-factor authentication
      }, 2000);
      
      const token = data.token;
      localStorage.setItem("token", token);
setLoader(false);
      try {
        
        const token = localStorage.getItem("token");
        const response = await fetch("https://automating.site:8080/admin/getmyprofile", {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `${token}`,
            },
            });
            
            const data = await response.json();
            if (response.status === 200) {
              localStorage.setItem("profile", JSON.stringify(data));
              navigate("/users");
        } else {
          setLoader(false);
          setShowError(true);
          throw new Error(data.message || "Failed to get profile.");
        }
      } catch (error) {
        setShowError(true);
        setLoader(false);
        console.error("Error getting profile:", error);
      }
    } else {
      setLoaderAuth(false);
      setShowError(true);
      throw new Error(
        data.message || "Failed to verify two-factor authentication."
      );
    }

  } catch (error) {
    console.error("Two-factor authentication error:", error);
    setShowError(true);
    // setErrorMessage(error.message);
  }


};

const renderInput = (inputProps, index) => (
  <input {...inputProps} key={index} className="otpInput" />
);
  return (
    <div className="flex flex-col  justify-center items-center h-screen ">
      <form
        onSubmit={handleSubmit}
        className=" relative gap-4 py-10 shadow-lg  flex  flex-col justify-center items-center px-20 rounded-lg"
      >
        {showError && (
          <motion.div
            initial={{ scale: 0.5, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            exit={{ scale: 0, opacity: 0 }}
            transition={{ duration: 0.3 }}
            className="bg-red-100 top-1 absolute border max-w-4xl w-full border-red-400 text-red-700 px-4 py-3 rounded "
            role="alert"
          >
            <strong className="font-bold">Invalid Credentials</strong>
            <span
              className="absolute top-0 bottom-0 right-0 px-4 py-3"
              onClick={() => setShowError(!showError)}
            >
              <svg
                className="fill-current h-6 w-6 text-red-500"
                role="button"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
              >
                <title>Close</title>
                <path d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z" />
              </svg>
            </span>
          </motion.div>
        )}
        <div className="text-left">
<p className="font-medium text-3xl text-left">Admin Sign In</p>
        </div>
        
        {/* Authentication Modal */}
        {showOTPError && <FailModal label="Invalid OTP" close={() => setShowOTPError(false)} />}
        {showModal &&    <TwoFactorAuthModal
      showModal={showModal}
      setShowModal={setShowModal}
      twoFactorAuthCode={twoFactorAuthCode}
      setTwoFactorAuthCode={setTwoFactorAuthCode}
      twoFactorAuthSubmit={twoFactorAuthSubmit}
      loader={loaderAuth}
    />}
        <TextField
          id="username"
          label="Username"
          variant="outlined"
          inputRef={usernameRef}
          sx={{ width: "100%" }}
          required
          className="w-full"
        />
        <TextField
          required
          className="w-full"
          id="password"
          label="Password"
          type="password"
          variant="outlined"
          inputRef={passwordRef}
          sx={{ width: "100%" }}
        />
        {/* Submit */} 
         <button
          className="py-2 px-6 w-full bg-black  text-white rounded text-xl"
          type="submit"
        >
          {loader ? (
            <div className="flex justify-center items-center py-5 gap-4">
              <ClipLoader color="#fff" size={30} />
              Loading...{" "}
            </div>
          ) : (
            "Sign In"
          )}
        </button>
        <div className="flex lg:flex-row flex-col items-center py-10 justify-center gap-2">
      <img src="/logo.png" className="w-10" />
       <p className="font-medium text-black text-xl sm:text-2xl lg:text-3xl">ContentMatch - Social Booster</p>
        </div>  
      </form>
    </div>
  );
}

export default Login;

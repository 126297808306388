import React from 'react';

function BanUserForm({ BanUser, userDetails, reason, handleReasonChange, description, handleDescriptionChange,loading }) {
  return (
    <form onSubmit={BanUser} className="flex justify-center items-center max-w-lg mx-auto gap-5 flex-col">
      <div className="flex flex-col items-start w-full pt-10 justify-start ">
        <p className="text-lg font-medium">Username</p>
        <p>{userDetails.user.username}</p>
      </div>
      <div className="flex flex-col justify-start w-full">
        <p className="text-lg font-medium">Reason <span className="text-gray-400 text-sm">(In Short)</span></p>
        <input
          value={reason}
          onChange={handleReasonChange}
          placeholder="Please Enter description"
          className="border border-black bg-white text-black p-2 rounded resize-none"
          rows="3"
          cols="50"
          required
          type="text"
        />
      </div>
      <div className="flex flex-col justify-start w-full">
        <p className="text-lg font-medium">Description</p>
        <textarea
          value={description}
          onChange={handleDescriptionChange}
          placeholder="Please Enter description"
          className="border border-black bg-white text-black p-2 rounded resize-none"
          rows="3"
          required
          cols="50"
        />
      </div>

      <button type="submit" disabled={loading} className="px-6 bg-black text-white my-5 w-full p-2 rounded-lg">
        {loading ? "Loading..." : "Ban User"}
      </button>
    </form>
  );
}

export default BanUserForm;
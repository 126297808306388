import React, { useState, useEffect } from "react";
import ResponsiveDrawer from "../../Layout/Layout";
import { Pagination } from "@mui/material";
import { ClipLoader } from "react-spinners";



function Paiduser() {
  const [reports, setReports] = useState([]);
  const [loading, setLoading] = useState(true);

  const [numPagination, setNumPagination] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    const fetchReports = async () => {
      const authToken = localStorage.getItem("token");
      try {
        const response = await fetch(
          `https://automating.site:8080/admin/getpaidusers?page=${currentPage}`,
          {
            method: "GET",
            headers: {
              Authorization: `${authToken}`
            }
          }
        );
        if(response.ok) {
          const data = await response.json();
          // console.log(data.reports.map(report => report.user && report.user.user.firstName));

          setReports(data.proUsers);
          console.log(reports);
          setLoading(false);
        }
        if (!response.ok) {
          throw new Error("Failed to fetch reports");
        }
        const data = await response.json();
        console.log(data);
        setReports(data.reports);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching reports:", error);
        setLoading(false);
      }
    };

    fetchReports();
  }, []);

  function shortenName(name) {
    if (name.length > 10) {
      return name.slice(0, 10);
    } else {
      return name;
    }
  }

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  if (loading) {
    return <div className="flex flex-col justify-center items-center py-8 "> 
<ClipLoader color="#000"  size={100} />
 <p>
  Loading...
 </p>
    </div>;
  }

  return (
    <ResponsiveDrawer>
      <div>
        <p className="flex justify-start items-start w-full text-black py-5 text-4xl">Premium Users</p>
        <hr className="border border-neutral-500 w-full mb-5"/>

        <table className="w-full">
          <thead className="text-left p-3 bg-neutral-200">
            <tr>
              <th className="p-3">User Name</th>
              <th className="p-3">Email</th>
              <th className="p-3">Phone</th> {/* Add Actions column */}
              <th className="p-3">Platform</th>
              <th className="p-3">Created At</th>
            </tr>
          </thead>
          <tbody>
            {reports.map((report) => (
              <tr key={report._id} className=" even:bg-neutral-200">
                <td className="p-3 font-sans">{report.firstName && report.firstName  ? report.firstName + " " + report.lastName : "N/A"}</td>
                <td className="p-3 font-sans">{report.email ? report.email : "N/A"}</td>
                <td className="p-3 font-sans">{shortenName(report.phoneNumber)}</td>
                <td className="p-3 font-sans text-sm">{Array.isArray(report.platform) ? report.platform.join(", ") : report.platform}</td>
                <td className="p-3 font-sans">{new Date(report.createdAt).toLocaleString()}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="flex justify-center items-center py-4">
        <Pagination
          count={numPagination}
          page={currentPage}
          onChange={handlePageChange}
          sx={{ marginTop: "10px" }}
        />
      </div>
    </ResponsiveDrawer>
  );
}

export default Paiduser;

import React, { useEffect } from "react";
import { BrowserRouter, Route, Routes, useNavigate } from "react-router-dom";
import Users from "./pages/Users/Users";
import UserDetails from "./pages/Users/UserDetails";
import Login from "./pages/Login";

import AllReports from "./pages/Reports/AllReports";
import Warn from "./pages/Warn/Warn";
import Paiduser from "./pages/PaidUsers/Paiduser";
import Loginmoderator from "./pages/Loginmoderator";
import Modcreate from "./pages/ModCreate/Modcreate";
import Ownerogs from "./pages/OwnerLogs/Ownerogs";



function ProtectedRoutes() {
  const navigate = useNavigate();
  const isAuthenticated = localStorage.getItem("token" || "Modtoken");

  useEffect(() => {
    if (!isAuthenticated) {
      navigate("/") 
    }
  }, [isAuthenticated, navigate]);

  return isAuthenticated ? (
    <Routes>
      <Route path="/users" element={<Users />} />
      <Route path="userdetails/:profileId" element={<UserDetails />} />
      <Route path="reports" element={<AllReports />} />
      <Route path="/warn" element={<Warn/>} />
      <Route path="/modcreation" element={<Modcreate/>} />
      <Route path="/paiduser" element={<Paiduser />} />
      <Route path="/ownerLogs" element={<Ownerogs />} />
    </Routes>
  ) : null;

}

function App() {
  return (
    <BrowserRouter>
    <Routes>
      <Route path="/" element={<Login />} />
      <Route path="/moderator" element={<Loginmoderator />} />
      <Route path="/*" element={<ProtectedRoutes />} />
      <Route path="/paiduser" element={<Paiduser />} />
    </Routes>
  </BrowserRouter>
  );
}

export default App;
import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import ResponsiveDrawer from "../../Layout/Layout";
import Modal from "@mui/material/Modal";
import { TextField, Box } from "@mui/material";

import { useNavigate } from "react-router-dom";
import { ClipLoader } from "react-spinners";
import { motion } from "framer-motion";

import UserDetail, {
  PostDetails,
} from "../../components/userDetailsCard/userDetail";
import axios from "axios";
import SuccessModal from "../../components/Modal/successModal";
import BanUserForm from "../../components/Users/BanUserForm";
import FailModal from "../../components/Modal/failModal";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  border: "0.5px solid #eee",
  borderRadius: "10px",
  boxShadow: 24,
  p: 4,
};

function UserDetails() {
  const warnmessageRef = useRef(null);
  const [userDetails, setUserDetails] = useState(null);
  const [allPosts, setAllPosts] = useState([]);
  const [allCommunity, setCommunity] = useState([]);
  const [loading, setLoading] = useState(true);
  //   Modal states
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const navigate = useNavigate();
  const [premiumLoader, setPremiumLoader] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const { profileId } = useParams();
  const [userBan, setUserBan] = useState(false);

  // For Tab switch between User details and ban user
  const [selectedTab, setSelectedTab] = useState("details");
  // To get user Role
  const [userRole, setUserRole] = useState(null);
const [bannedUserSuccess, setBannedUserSuccess] = useState(false);
const [bannedUserFail,setBannedUserFail] = useState(false)

// For Ban User 
const [reason, setReason] = useState('');
const [description, setDescription] = useState('');
  // For Premium Modal
  const [showPremiumModal, setShowPremiumModal] = useState(false);

  // For Gift premium Value
  const [email, setEmail] = useState("");

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handleSubmit = async () => {
    const message = warnmessageRef.current.value;
    try {
      const response = await fetch(
        process.env.REACT_APP_AWSURL + "admin" + "/warnmessage",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: localStorage.getItem("token"), // Add the token to the headers
          },
          body: JSON.stringify({ profileId, message }),
        }
      );
      if (!response.ok) {
        throw new Error("Failed to send warning message");
      }
      handleClose(); // Close the modal after successful submission
      // You may perform additional actions after successful submission if needed
    } catch (error) {
      console.error("Error sending warning message:", error);
      // Handle error if submission fails
    }
  };

  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_AWSURL}admin/getsepcificuser?profileId=${profileId}`
        );
        if (!response.ok) {
          throw new Error("Failed to fetch user details");
        }
        const data = await response.json();
setUserBan(data.isBan)
        setUserDetails(data.specificProfile);

        setAllPosts(data.allPostByUser); // Add this line
        setCommunity(data.communityList);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching user details:", error);
        setLoading(false);
      }
    };
    // We have all the data in the profile in local storage we have to parse it to use it as object
    const getUserRoleFromLocal = JSON.parse(localStorage.getItem("profile"));
    setUserRole(getUserRoleFromLocal.role);
    // console.log(userRole,"user role from local storage")
    fetchUserDetails();
  }, [profileId]);

  if (loading) {
    return (
      <div className="flex justify-center items-center py-10">
        {/* <img src="/Loader.gif" className=" w-1/2 lg:w-2/5" /> */}
        <ClipLoader color="#000" size={100} />
      </div>
    );
  }

  if (!userDetails) {
    return <div>User not found</div>;
  }

  const handlePremium = async (e) => {
    e.preventDefault();
    try {
      setPremiumLoader(true);
      const response = await fetch(
        process.env.REACT_APP_AWSURL + "admin/owner/gift",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: localStorage.getItem("token"), // Add the token to the headers
          },

          body: JSON.stringify({
            userId: userDetails.user._id,
            months: email,
          }),
        }
      );
      if (response.status === 200) {
        setPremiumLoader(false);
        setShowPremiumModal(false);
        setSuccessMessage("Premium Gifted Successfully");
      }
      if (!response.ok) {
        setShowPremiumModal(false);
        throw new Error("Failed to send warning message");
      }
      // handleClose(); // Close the modal after successful submission
      // You may perform additional actions after successful submission if needed
    } catch (error) {
      setPremiumLoader(false);
      setShowPremiumModal(false);
      setSuccessMessage("Some Error Occured Please Try Again Later");
      console.error("Error sending warning message:", error);
      // Handle error if submission fails
    }
  };




  // Handle input changes
  const handleReasonChange = (e) => {
    setReason(e.target.value);
  };

  const handleDescriptionChange = (e) => {
    setDescription(e.target.value);
  };

  // BanUser function
  const BanUser = async (e) => {
   e.preventDefault();
    const token = localStorage.getItem('token'); // Retrieve token from localStorage
    const profileId = userDetails._id; // Assuming `id` is the profileId in userDetails
setLoading(true)
    const apiUrl = "https://automating.site:8080/admin/banuser";
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': `${token}`
    };
    const body = {
      profileId,
      reason,
      description
    };

    try {
      const response = await axios.post(apiUrl, body, { headers });
      console.log('BanUser Response:', response.data);
      // Handle success (e.g., show a success message)
      if(response.status === 200){
        setBannedUserSuccess(true);
        setSelectedTab("details")
        setUserBan(false)
setLoading(false)
setTimeout(() => {
  window.location.reload()
}, 2000);
        }

    } catch (error) {
      console.error('BanUser Error:', error);
      setBannedUserFail(true);
      // Handle error (e.g., show an error message)
    }
  };


console.log(userBan,"user ban or not")
  return (
    <ResponsiveDrawer className="relative">
      {/* Modal */}

      <div className="relative">
        <div className="py-10 grid grid-cols-3 w-full">
          <div>
            <p
              onClick={() => navigate("/users")}
              className="flex justify-start items-center gap-3 border hover:scale-105 transition-all duration-200 delay-150 ease-out border-black rounded-md  py-2 px-4 w-28 cursor-pointer"
            >
              <img
                width="24"
                height="24"
                src="https://img.icons8.com/ios-glyphs/30/long-arrow-left.png"
                alt="long-arrow-left"
              />{" "}
              Back
            </p>
          </div>
        </div>
        <div className="flex items-center   justify-center w-full gap-x-4 py-1  rounded-md ">
          <p
            className={`text-lg lg:text-lg ${
              selectedTab === "details"
                ? " border-black border-b-2  text-black"
                : "text-gray-400"
            }  cursor-pointer p-3`}
            onClick={() => setSelectedTab("details")}
          >
            User Details
          </p>
          
          <p
            className={`text-lg lg:text-lg ${userBan ? "hidden" :"block"} ${selectedTab === "ban" ? " border-black border-b-2  text-black" : "text-gray-400" } cursor-pointer p-3`}
            onClick={() => setSelectedTab("ban")}
          >
            Ban User
          </p>
         
        </div>
        <hr className="-translate-y-1" />
       {selectedTab=== "details" && (
        <div>
          {" "}
          <div
            className={`grid sm:grid-cols-3 grid-cols-2 lg:grid-cols-5 gap-4  lg:gap-8 py-10 max-w-5xl w-full ${
              (successMessage === "Premium Gifted Successfully") |
              showPremiumModal
                ? "blur-sm"
                : "blur-none"
            }`}
          >
            <UserDetail label="Username" value={userDetails.user.username} />
            <UserDetail label="FirstName" value={userDetails.user.firstName} />
            <UserDetail label="LastName" value={userDetails.user.lastName} />
            <UserDetail
              label="PhoneNumber"
              value={userDetails.user.phoneNumber}
            />
            {userDetails.profilepic && (
              <div className="flex gap-4 flex-col justify-start items-start">
                <p className="text-lg">
                  <strong>Profile Pic:</strong>
                </p>
                <img
                  src={userDetails.profilepic}
                  className="w-36 border border-[#eee] p-2 rounded-lg"
                />
              </div>
            )}
          </div>
          <div className="text-xl lg:text-2xl">
            {allPosts.length === 0
              ? "No Post Available from this User"
              : "Posts By User"}
          </div>
          {allPosts.map((post, index) => (
            <div
              key={index}
              className="grid sm:grid-cols-2 grid-cols-1 lg:grid-cols-3 gap-x-8 gap-y-4 py-4"
            >
              <PostDetails label="Description" value={post.description} />
              <PostDetails label="Link" value={post.link} />
              <PostDetails label="Community Type" value={post.type} />
            </div>
          ))}
          {/* table for showing the post  */}
          <table className="w-4/5 py-10">
            <thead>
              <tr className="text-left">
                {allPosts.length === 0 ? "" : "Community"}
              </tr>
            </thead>
            <tbody>
              {allCommunity.map((community, index) => (
                <tr key={index}>
                  <td>{community.name}</td>
                </tr>
              ))}
            </tbody>
          </table>
          {/* Add more details as needed */}
          {showPremiumModal && (
            <div
              id="authentication-modal"
              tabindex="-1"
              aria-hidden="true"
              className="absolute   top-10 right-1/3 left-0 z-50 justify-center items-center  md:inset-0 h-[calc(100%-1rem)] max-h-full"
            >
              <div className="relative p-4 w-full max-w-md translate-x-[80%] translate-y-[60%] max-h-full">
                {/* <!-- Modal content --> */}
                <div className="relative bg-white rounded-lg shadow dark:bg-white">
                  {/* <!-- Modal header --> */}
                  <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
                    <p className="text-xl font-semibold text-gray-900 dark:text-black font-sans">
                      Duration for gift premium?
                    </p>
                    <button
                      onclick={() => setShowPremiumModal(false)}
                      type="button"
                      className="end-2.5 text-black bg-transparent  rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:bg-white hover:rotate-12 transition-all duration-100 ease-in-out delay-100 "
                      data-modal-hide="authentication-modal"
                    >
                      <svg
                        className="w-3 h-3"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 14 14"
                        onClick={() => setShowPremiumModal(false)}
                      >
                        <path
                          stroke="currentColor"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                        />
                      </svg>
                      <span className="sr-only">Close modal</span>
                    </button>
                  </div>
                  {/* <!-- Modal body --> */}
                  <div className="p-4 md:p-5">
                    <form
                      className="space-y-4"
                      action="#"
                      onSubmit={handlePremium}
                    >
                      <div>
                        <input
                          value={email}
                          onChange={handleEmailChange}
                          required
                          type="number"
                          name="email"
                          id="email"
                          className="bg-[#D6D6D6] border border-gray-300 text-black text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  dark:placeholder-gray-400 dark:text-black"
                          placeholder="How many months ? (1,2,3,4,5,6)"
                        />
                      </div>

                      <button
                        type="submit"
                        className="w-full text-white bg-black  font-medium rounded-lg px-5 py-2.5 text-center dark:bg-black font-sans text-lg"
                      >
                        Submit
                      </button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          )}
      

        <div className="flex justify-start gap-x-4">
          <button
            className="bg-yellow-300  p-4  text-black font-medium font-sans rounded"
            onClick={handleOpen}
          >
            Warn User
          </button>
          {userRole === "moderator" ? (
            ""
          ) : (
            <button
              className="bg-green-500 p-4 rounded-lg text-white font-medium font-sans"
              // onClick={handlePremium}
            >
              {premiumLoader ||
              successMessage === "Premium Gifted Successfully" ? (
                <div className="flex justify-center items-center gap-x-5">
                  <ClipLoader color="#000" size={20} />
                  <p>Loading</p>
                </div>
              ) : (
                <button
                  //  onClick={() => setShowPremiumModal(true)}
                  onClick={() => setShowPremiumModal(true)}
                >
                  Gift Premium
                </button>
              )}
            </button>
          )}
        </div>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          className="flex justify-center items-center px-5 border border-[#eee] rounded-lg relative"
        >
          <Box sx={style}>
            <img
              width="94"
              height="94"
              src="https://img.icons8.com/3d-fluency/94/high-priority.png"
              className="absolute right-[40%] -top-[75px]"
              alt="high-priority"
            />
            <div className="flex flex-col justify-center py-0 my-0  items-center">
              <p className="pb-5 text-2xl text-center  font-semibold font-sans">
                Enter your Warn Message
              </p>
              <TextField
                id="Warnmessage"
                label="Warnmessage"
                variant="outlined"
                inputRef={warnmessageRef}
                sx={{
                  width: "80%",
                }}
              />
              <button
                className="bg-black p-4 rounded my-5 w-4/5 text-white"
                onClick={handleSubmit}
              >
                Submit
              </button>
            </div>
          </Box>
        </Modal>
        {successMessage && (
          <motion.div
            initial={{ opacity: 0, y: -50 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -50 }}
            transition={{ duration: 0.5 }}
            className="flex flex-col top-1/3 justify-center gap-5 items-center bg-white rounded-2xl shadow-lg absolute px-5 py-10 right-1/3"
          >
            <p className="font-sans text-3xl font-medium">{successMessage}</p>
            {successMessage === "Premium Gifted Successfully" ? (
              <img
                src="https://img.icons8.com/3d-fluency/94/approval.png"
                className="w-20"
              />
            ) : (
              <img
                className="w-20"
                src="https://img.icons8.com/3d-fluency/94/cancel.png"
              />
            )}
            <button
              className="bg-black text-white rounded-lg px-5 py-2 font-sans font-medium"
              onClick={() => setSuccessMessage(false)}
            >
              Close
            </button>
          </motion.div>
        )}
      </div>
    )}


      {selectedTab === "ban" && (
        <BanUserForm
          BanUser={BanUser}
          userDetails={userDetails}
          reason={reason}
          handleReasonChange={handleReasonChange}
          description={description}
          loading={loading}
          handleDescriptionChange={handleDescriptionChange}
        />
      )}
      {bannedUserSuccess && (<SuccessModal label="User Banned Successfully" close={()=>setBannedUserSuccess(!bannedUserSuccess)} />)}
    {bannedUserFail && (<FailModal label="User Banned Failed, it's not you It's us" close={()=>setBannedUserFail(!bannedUserFail)} />)}
     

      </div>
    </ResponsiveDrawer>
  );
}

export default UserDetails;

import React from 'react';
import OTPInput from 'react-otp-input'; // Assuming you have this component
import ClipLoader from 'react-spinners/ClipLoader'; // Assuming you have this component

const TwoFactorAuthModal = ({ showModal, setShowModal, twoFactorAuthCode, setTwoFactorAuthCode, twoFactorAuthSubmit, loader }) => {
// Define a renderInput function
const renderInput = (inputProps, index) => (
    <input {...inputProps} key={index} className="otpInput" />
  );
  
    return (
    <div className="fixed z-40 inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full flex justify-center items-center">
      <div className="bg-white flex flex-col justify-center items-start max-w-sm w-full gap-4 p-5 rounded-lg shadow-lg ">
        <div className="flex flex-row justify-between w-full items-start">
          <p className="font-bold mb-4 font-serif text-lg">Two Factor Authentication</p>
          <img width="24" height="24" src="https://img.icons8.com/ios/50/cancel.png" className="hover:rotate-12 transition-all duration-300 cursor-pointer" onClick={() => setShowModal(!showModal)} alt="cancel"/>
        </div>
        <label>Please Enter the code</label>
        <OTPInput
  value={twoFactorAuthCode}
  onChange={setTwoFactorAuthCode}
  numInputs={6}
  separator={<span>-</span>}
  inputStyle={"text-black font-lato w-24"}
  renderInput={renderInput} // Pass the function here
/>
        <button type="submit" onClick={twoFactorAuthSubmit} className="mt-4 bg-black text-white w-full font-bold py-2 px-4 rounded">
        Sign In
        </button>
      </div>
    </div>
  );
};

export default TwoFactorAuthModal;
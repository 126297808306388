import React, { useRef, useState } from "react";
import TextField from "@mui/material/TextField";
import { useNavigate } from "react-router-dom";
import { ClipLoader } from "react-spinners";
import { motion } from "framer-motion";

function Loginmoderator() {
  const navigate = useNavigate();
  const usernameRef = useRef(null);
  // Validation error state
  const [showError,setShowError] = useState(false);

  const passwordRef = useRef(null);
  const [loader, setLoader] = useState(false);
  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoader(true);
    const username = usernameRef.current.value;
    const password = passwordRef.current.value;
    console.log(username, password);

    try {
      // Make API call to AWS backend
      const response = await fetch(
        "https://automating.site:8080/admin/loginOwnMod",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            username,
            password,
          }),
        }
      );

      const data = await response.json();
      if (response.status === 200) {
        setLoader(false);
        localStorage.setItem("token", data.token);
        localStorage.setItem("id", data.existingAdmin._id);
        localStorage.setItem("email", data.existingAdmin.email);
        localStorage.setItem("role", data.existingAdmin.role);
        setShowError(false);
        navigate("/users");
      }
      if (!response.ok) {
        setShowError(true);
        setLoader(false);
        throw new Error(data.message);
      }
      
      // If API call is successful, handle the response data
      console.log(data);
      // navigate("/users");
    } catch (error) {
      console.error("Error logging in:", error);
      // Handle error
      setLoader(false);
      setShowError(true);
    }
    setTimeout(() => {
            setShowError(false);
    }, 3000);
  };

  return (
    <div className="flex flex-col  justify-center items-center h-screen ">
      <form
        onSubmit={handleSubmit}
        className="w-[50%] relative gap-4 py-20 shadow-lg border border-red-100 flex bg-[#eee] flex-col justify-center items-center rounded-lg"
      >
        {/* Validation Error */}
        {showError && (
          <motion.div
            initial={{ scale: 0.5, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            exit={{ scale: 0, opacity: 0 }}
            transition={{ duration: 0.3 }}
            className="bg-red-100 top-1 absolute border max-w-4xl w-full border-red-400 text-red-700 px-4 py-3 rounded "
            role="alert"
          >
            <strong className="font-bold">Invalid Credentials</strong>
            <span
              className="absolute top-0 bottom-0 right-0 px-4 py-3"
              onClick={() => setShowError(!showError)}
            >
              <svg
                className="fill-current h-6 w-6 text-red-500"
                role="button"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
              >
                <title>Close</title>
                <path d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z" />
              </svg>
            </span>
          </motion.div>
        )}

        <img src="/titleImage.png" className="w-3/5" />
        <p className="text-black capitalize text-4xl pb-8 text-center">
          Welcome To Moderator <br /> Page
        </p>

        <TextField
          id="username"
          label="Username"
          variant="outlined"
          inputRef={usernameRef}
          sx={{
            width: "80%",
          }}
          required
        />
        <TextField
          required
          id="password"
          label="Password"
          type="password"
          variant="outlined"
          inputRef={passwordRef}
          sx={{ width: "80%" }}
        />
        <button
          className="py-2 px-6 w-4/5 bg-black  text-white rounded text-xl"
          type="submit"
        >
          {loader ? (
            <div className="flex justify-center items-center py-5 gap-4">
              <ClipLoader color="#fff" size={30} />
              Loading...{" "}
            </div>
          ) : (
            "Submit"
          )}
        </button>
      </form>
    </div>
  );
}

export default Loginmoderator;

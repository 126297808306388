import React, { memo } from "react";
import ResponsiveDrawer from "../../Layout/Layout";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Pagination from "@mui/material/Pagination";
import { ClipLoader } from "react-spinners";
import axios from "axios";
import { UnbanUser } from "../../utils/api";
// import { useAuth } from "../../useAuth";

const Users = memo(function Users() {
  const [profile, setProfile] = useState([]);
  const [loading, setLoading] = useState(true);
  const [numPagination, setNumPagination] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [loader, setLoader] = useState(true);
  const [selectedTab, setSelectedTab] = useState("userDetails");
  const [bannedUsers, setBannedUsers] = useState([]);
  const [unbanningProfileId, setUnbanningProfileId] = useState(null);

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    // Move the condition inside the useEffect hook
    if (selectedTab === "bannedUsers") {
      const token = localStorage.getItem("token");
      const fetchBannedUsers = async () => {
        try {
          const response = await axios.get(
            "https://automating.site:8080/admin/getbannedusers",
            {
              headers: {
                Authorization: token, // Replace 'your_token_here' with your actual token
              },
            }
          );
          if (response.status === 200) {
            setBannedUsers(response.data);
           
          }
          // Assuming you will set the state or perform some actions with the response
        } catch (error) {
          console.error("Error fetching banned users:", error);
        }
      };

      fetchBannedUsers();
    }

    const fetchUsers = async () => {
      setLoader(true);
      try {
        const response = await fetch(
          `${process.env.REACT_APP_AWSURL}admin/getalluserspaginated?page=${currentPage}`
        ); // Assuming the API endpoint is '/getalluserspaginated' and passing page=1 for the first page
        if (!response.ok) {
          throw new Error("Failed to fetch users");
        }
        const data = await response.json();
        if (data && data.profiles) {
          setLoader(false);
          // console.log("No users found",data.profiles);
          // console.log("No users found",data);
          setProfile(data.profiles);
        }
        setNumPagination(data.paginatedButtons);
        setLoading(false);
        console.log(data);
      } catch (error) {
        setLoader(true);
        console.error("Error fetching users:", error);
        // Handle error
        setLoading(false);
      }
    };

    fetchUsers();
  }, [currentPage, selectedTab]);

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  if (loading) {
    return (
      <div className="flex justify-center translate-y-1/2 items-center w-full">
        <ClipLoader color="#000" loading={loading} size={100} />
      </div>
    );
  }
  console.log(profile);

  const handleUnbanClick = async (profileId, description) => {
    setIsLoading(true);
    setUnbanningProfileId(profileId);
    const token = localStorage.getItem("token");
    try {
      const response = await axios.post(
        "https://automating.site:8080/admin/unbanuser",
        {
          profileId,
          description,
        },
        {
          headers: {
            Authorization: token, // Replace YOUR_TOKEN_HERE with your actual token
          },
        }
      );
      console.log("Unban successful", response.data);
      setBannedUsers((currentUsers) =>
        currentUsers.filter((user) => user.profile._id !== profileId)
      );
      // Handle success (show message, update UI, etc.)
    } catch (error) {
      console.error("Error unbanning user", error);
      // Handle error (show error message, etc.)
    } finally {
      setIsLoading(false);
      setUnbanningProfileId(null);
    }
  };

  return (
    <ResponsiveDrawer>
      <div className="flex flex-col w-full justify-center items-center relative py-2">
        <div className="flex gap-x-4 justify-center items-center p-0 w-full border">
          <p
            className=" text-black py-5 text-xl cursor-pointer"
            onClick={() => setSelectedTab("userDetails")}
          >
            All users
          </p>
          <p
            className=" text-black py-5 text-xl cursor-pointer"
            onClick={() => setSelectedTab("bannedUsers")}
          >
            Banned users
          </p>
          {/* <hr className="border border-black" /> */}
        </div>

        {selectedTab === "userDetails" && (
          <>
            <table
              className={`${
                loader ? "blur-sm" : "blur-none"
              }  w-full  overflow-h-scroll h-[calc(100%-200px)]`}
            >
              <thead className="w-full bg-neutral-200 ">
                <tr className="text-left ">
                  <th className="py-4 ">Username</th>
                  <th className="py-4 ">Email</th>
                  <th className="py-4 ">First Name</th>
                  <th className="py-4">Last Name</th>
                  <th className="py-4">Phone Number</th>
                  <th className="py-4">Paid</th>
                  <th className="py-4">Banned User</th>
                </tr>
              </thead>
              <tbody className="h-[50vh]">
                {profile.map((profile, index) => (
                  <tr key={index} className=" even:bg-neutral-200 ">
                    <td className="pl-2 py-5 capitalize text-sm underline tracking-wider leading-8 hover:text-blue-600">
                      <Link to={`/userdetails/${profile._id}`}>
                        {profile.user ? profile.user.username : "N/A"}
                      </Link>
                    </td>
                    <td className="tracking-wider text-sm">
                      {profile.user ? profile.user.email : "N/A"}
                    </td>
                    <td className="tracking-wider text-sm capitalize">
                      {profile.user ? profile.user.firstName : "N/A"}
                    </td>
                    <td className="tracking-wider text-sm capitalize">
                      {profile.user ? profile.user.lastName : "N/A"}
                    </td>
                    <td className="tracking-wider text-sm capitalize">
                      {profile.user ? profile.user.phoneNumber : "N/A"}
                    </td>
                    <td>
                      {profile.user && profile.user.isPaid ? (
                        <button className="bg-green-500 text-white p-3 rounded w-[80px]">
                          Paid
                        </button>
                      ) : (
                        <button className="bg-red-500 text-white p-3 rounded w-[80px]">
                          Unpaid
                        </button>
                      )}
                    </td>
                    <td className="tracking-wider text-sm capitalize">
                      {profile.isBanned ? "Banned" : "Unbanned"}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            {loader && (
              <div className="flex absolute justify-center items-center gap-2">
                <ClipLoader color="#000" size={100} />
                <p className="text-black font-medium">Loading </p>
              </div>
            )}
            <Pagination
              count={numPagination}
              page={currentPage}
              onChange={handlePageChange}
              sx={{ marginTop: "10px" }}
            />
          </>
        )}
        {selectedTab === "bannedUsers" && bannedUsers.length > 0 && (
          <table className="w-full">
            <thead className="w-full bg-neutral-200 py-5">
              <tr className="text-left ">
                <th>ID</th>
                <th>Name</th>
                <th>Email</th>
                <th>Reason</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody className="py-2 border-b border-gray-500">
              {bannedUsers.map((user) => (
                <tr key={user.profileId}>
                  <td className="py-3">{user.profile.user._id}</td>
                  <td>{`${user.profile.user.firstName} ${user.profile.user.lastName}`}</td>
                  <td>{user.profile.user.phoneNumber}</td>{" "}
                  {/* Assuming phoneNumber is used as an email here */}
                  <td>{user.reason}</td>
                  <td>
                    {/* Example action button */}
                    <button
                      key={user.profileId}
                      disabled={
                        isLoading && unbanningProfileId === user.profileId
                      } // Disable only the button being clicked
                      onClick={() =>
                        handleUnbanClick(user.profile._id, user.description)
                      }

                      className="px-5 py-2 rounded-md bg-black text-white"
                    >
                      {isLoading && unbanningProfileId === user.profile._id
                        ? "Unbanning..."
                        : "Unban"}
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
        {selectedTab === "bannedUsers" && bannedUsers.length === 0 && (
          <div className="flex justify-center items-center w-full py-10">
            <p className="sm:text-xl text-lg lg:text-2xl">
              <video muted width={200} height={200} loop>
                <source src="/notFound.mp4" type="video/mp4" />
              </video>
              No banned users found
            </p>
          </div>
        )}
      </div>
    </ResponsiveDrawer>
  );
});

export default Users;

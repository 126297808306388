import React, { useEffect, useState } from 'react'
import ResponsiveDrawer from '../../Layout/Layout'
import { ClipLoader } from 'react-spinners';

const Warn = () => {
  const [warnedUsers, setWarnedUsers] = useState([]);
  const [loader,setLoader] = useState(false);
  useEffect(() => {
    const fetchUserDetails = async () => {
      
      const authToken = localStorage.getItem("token");
      setLoader(true);
      try {
        const response = await fetch(
          process.env.REACT_APP_AWSURL + "admin" + "/getwarnedusers",
          {
            headers: {
              Authorization: "Bearer " + authToken // Replace authToken with your actual token value
            }
          }
        );
        setLoader(true);
        if(response.status === 200){
          setLoader(false);

        }
        if (!response.ok) {
          setLoader(false);
          throw new Error("Failed to fetch users");
        }
        const data = await response.json();
        setWarnedUsers(data.warnedUsers);
        console.log(data.warnedUsers);
      } catch (error) {
        setLoader(false);
        console.error("Error fetching users:", error);
      }
    }
    fetchUserDetails();
  }, [])
  return (
    <div>
        <ResponsiveDrawer>
            <p className='flex justify-start items-start text-4xl py-5'>Warned User's List</p>
            <hr className="border border-neutral-500 w-full mb-5"/>
            <table className='w-full relative'>
      <thead className="text-left  bg-neutral-200">
        <tr>
          <th className="p-3">ID</th>
          <th className="p-3">Profile</th>
          <th className="p-3">Warn Message</th>
          <th className="p-3">Created At</th>
        </tr>
      </thead>
      <tbody>
        {warnedUsers.map(user => (
          <tr key={user._id} className=" even:bg-neutral-200">
            <td className="p-3">{user._id}</td>
            <td className="p-3">{user.profile.user.firstName + " " + user.profile.user.lastName}</td>
            <td className="p-3">{user.warnMessage}</td>
            <td className="p-3">{new Date(user.createdAt).toLocaleDateString()}</td>
          </tr>
        ))}
      </tbody>
      {loader && 
      <div className='absolute right-[50%]'>
      <ClipLoader color="#000" className='border-4 border-black' size={150}  />
      </div>
      }
    </table>
        </ResponsiveDrawer>
    </div>
  )
}

export default Warn
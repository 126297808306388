import React, { useState } from 'react';
import ResponsiveDrawer from '../../Layout/Layout';
import axios from 'axios';
import { ClipLoader } from 'react-spinners';

const Modcreate = () => {
  const [form, setForm] = useState({
    email: '',
    firstName: '',
    lastName: '',
    username: '',
    password: '',
    phoneNumber: ''
  });
  const [errorMessage, setErrorMessage] = useState('');
  const [loader, setLoader] = useState(false);

  const handleChange = (e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value
    });
  };

  const handleSubmit = async (e) => {
      setLoader(true);
      const authToken = localStorage.getItem('token');
      e.preventDefault();
      try {
          const response = await axios.post('https://automating.site:8080/admin/createmoderator', form, {
              headers: {
                  Authorization: authToken
                }
            });
            
            if (response.status === 200 || response.status === 201) {

                setForm({'email': '', 'firstName': '', 'lastName': '', 'username': '', 'password': '', 'phoneNumber': ''})
        setErrorMessage('Moderator created successfully');
      } else if(response.message === "Email or username already exists" || response.status === 400) {
        setErrorMessage('Email or username already exists');
        setForm({'email': '', 'firstName': '', 'lastName': '', 'username': '', 'password': '', 'phoneNumber': ''})
      } 
    } catch (error) {
      console.error(error);
      setForm('')
      setErrorMessage('Email or username already exists');
    } finally {
      setLoader(false);
    }
  };

setTimeout(() => {
    setErrorMessage('');
}, 5000);

  return (
    <ResponsiveDrawer>
    <p className='flex justify-start items-start w-full lg:text-left text-center text-black py-5 sm:text-2xl text-xl lg:text-4xl'>Moderator's Credentials Creation</p>
      <div className='relative bg-white  max-w-3xl w-full mx-auto '>
        {errorMessage && (
          <div className={`absolute ${errorMessage === "Moderator created successfully" ? "bg-teal-100 border-t-4 border-teal-500 rounded-b text-teal-900 " :"bg-red-100 border border-red-400 text-red-700 "}  px-4 py-3 rounded right-1`} role="alert">
            <strong className="font-bold">{errorMessage}</strong>
          </div>
        )}
        <form className='grid sm:grid-cols-2 grid-cols-1 lg:grid-cols-1 px-5 w-full gap-x-3 gap-y-5  mx-auto py-8 my-1'>
          <input type="email" name="email" value={form.email} className='p-3 rounded-md bg-[#D6D6D6]' onChange={handleChange} placeholder="Email" required />
          <input type="text" name="firstName" value={form.firstName} className='p-3 rounded-md bg-[#D6D6D6]' onChange={handleChange} placeholder="First Name" required />
          <input type="text" name="lastName" value={form.lastName} className='p-3 rounded-md bg-[#D6D6D6]' onChange={handleChange} placeholder="Last Name" required />
          <input type="text" name="username" value={form.username} className='p-3 rounded-md bg-[#D6D6D6]' onChange={handleChange} placeholder="Username" required />
          <input type="password" name="password" value={form.password} className='p-3 rounded-md bg-[#D6D6D6]' onChange={handleChange} placeholder="Password" required />
          <input type="tel" name="phoneNumber" value={form.phoneNumber} className='p-3 rounded-md bg-[#D6D6D6]' onChange={handleChange} placeholder="Phone Number" required />
        </form>
        <div className='w-full flex justify-center items-center'>
          <button onClick={handleSubmit} type="submit" className='w-11/12  bg-black  text-white font-sans rounded-md p-4 text-xl font-medium'>
            {loader ? (
              <div className='flex items-center justify-center gap-x-4'>
                <ClipLoader color="#fff" size={20} /> Loading...
              </div>
            ) : (
              'Submit '
            )}
          </button>
        </div>
      </div>
    </ResponsiveDrawer>
  );
};

export default Modcreate;
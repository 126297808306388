import React from 'react'

const UserDetail = ({label,value}) => {
  return (
    <div className="flex gap-2 flex-col justify-start items-start">
    <p className="text-lg">
      <strong>{label}:</strong>
    </p>
    <p className={`capitalize ${label === 'Username' ? 'text-black' : ''}`}>
      {value}
    </p>
  </div> 
)
}

export default UserDetail

export const PostDetails = ({label,value}) => {
    return (
        <div className="flex flex-col justify-start items-start gap-2">
        <p className="font-sans font-medium text-lg">{label}</p>
        <p className="max-w-lg w-full">{value}</p>
    </div>
)
}


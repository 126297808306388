export const UnbanUser = async (profileId, description) => {
    const url = "https://automating.site:8080/admin/unbanuser";
    const token = localStorage.getItem("token");
    const headers = {
      "Content-Type": "application/json",
      "Authorization": `${token}`
    };
    const body = JSON.stringify({
      profile_id: profileId,
      description: description
    });
  
    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: headers,
        body: body
      });
      const data = await response.json();
      console.log('Success:', data);
      return data; // Return the response data for further processing
    } catch (error) {
      console.error('Error:', error);
      throw error; // Rethrow the error for handling by the caller
    }
  };